<template>
  <div id="app">
    <div class="loading" v-if="!message.error">กรุณารอสักครู่ ....</div>
    <div class="error-message" v-else>{{ message.text }}</div>
    <div @click="redirect" class="redirect">
      <small><u>คลิกหากไม่มีอะไรเกิดขึน</u></small>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
export default {
  name: "App",
  components: {},
  data() {
    return {
      message: {
        error: false,
        text: "",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.redirect();
    }, 1000);
  },
  async beforeCreate() {
    liff.init({ liffId: "1661307043-26mPmz1k" });
  },
  methods: {
    async redirect() {
      let isInLine = liff.isInClient();

      let isMobileDevice = window.matchMedia("(any-pointer: coarse)").matches;

      if (!isInLine && isMobileDevice) {
        return (window.location.href = `line://app/1661307043-26mPmz1k?redirect=/beautyAward`);
      } else {
        window.location.href =
          "https://centralbeauty-campaign.d-dots.com/beautyAward";
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  margin: auto;
}
.error-message {
  margin: auto;
  font-weight: bold;
  font-size: 24px;
}
.redirect {
  cursor: pointer;
  margin: 10px 0;
}
</style>
